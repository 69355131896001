import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export interface User {
  name: string,
  department: string, 
  role: string,
  image: string,
  profile_text: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  adminId: string;
  page: number;
  pageSize: number;
  isOpenDeleteModal: boolean;
  adminList: any[];
  totalUser: number,
  loading:boolean,
  delete:boolean,
  selectName:string,
  errorMessage:string,
   // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminListId: string = "";
  getDeleteAdminId: string = "";
  updateAdminSsoId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      adminId: "",
      page: 1,
      pageSize: 8,
      isOpenDeleteModal: false,
      adminList: [],
      totalUser: 0,
      loading:false,
      delete:false,
      selectName:'',
      errorMessage:'',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const adminApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const adminResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const adminErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


    if (adminApiRequestCallId === this.getAdminListId) {
        const { data,errors } = adminResponseJson;
        if(errors){
            this.setState({ loading: false,adminList:[]})
        }
        const modifyData = data.map((item: any) => ({
          ...item.data.attributes.data,
          ...item.data.attributes.access,
          ...item.data.attributes.image,
          ...item.data
        }));
        this.setState({ adminList: modifyData, totalUser: adminResponseJson.total_admin_counts, loading: false })
      }
      else if (adminApiRequestCallId === (this.getDeleteAdminId) ) {
        this.getRender(adminResponseJson)
      } 
      else if (adminApiRequestCallId === (this.updateAdminSsoId) ) {
        this.getRender1(adminResponseJson)
      } 
      else {
        console.log(adminErrorReponse);
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
 
  apiCall = async (data: any) => {
    const token = await getStorageData("token");
    
    const { contentType, method, endPoint,body , formBody} = data;
    const header = {
        "Content-Type": contentType,
        token
    };
    const requestAdminMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestAdminMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestAdminMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestAdminMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestAdminMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body) || formBody
    );
    runEngine.sendMessage(requestAdminMsg.id, requestAdminMsg);
    return requestAdminMsg.messageId;
  };

  async componentDidMount() {
    this.getAdminList(this.state.page);
  }

  getRender=(responseJson:any)=>{
    const { message,errors } = responseJson;
    if(errors){
        this.setState({errorMessage:errors})
    }
    if (message) {
      this.getAdminList(1);
      this.handleOpenCloseModal({})
      this.setState({errorMessage:''})
    }
  };

  getRender1=(responseJson:any)=>{
    const { message } = responseJson;
    if (message) {
      this.getAdminList(this.state.page);
    }
  };

  getAdminList = async (page: number) => {
    this.setState({ loading: true })
    this.getAdminListId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "GET",
      endPoint: `${configJSON.adminListApiEndPoint}?page=${page}`,
    });
  };

  deleteAdminAccount = async () => {
    this.getDeleteAdminId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "DELETE",
      endPoint: `${configJSON.deleteUserAccountApiEndPoint}/${this.state.adminId}`,
    });
  };

  onPageChanges = (page: number) => {
    this.setState({ page, loading: true })
    this.getAdminList(page)
  };

  handleOpenCloseModal = (item:any) => {
    this.setState((prevState) => ({ isOpenDeleteModal: !prevState.isOpenDeleteModal,adminId:item.id,selectName:item.Name,errorMessage:'' }))
  };

  handleSuccessfully = () => {
    this.setState({ isOpenDeleteModal: false,adminId:''})
    this.getAdminList(this.state.page);
  };


  handleDeleteConf = () => {
    this.setState((prevState) => ({ delete: !prevState.delete}))
    this.deleteAdminAccount()
  };

  handleCheckbox=async(id:any,email:any,sso:any)=>{
    let ssoModify=!sso;
    this.updateSso(id,email,ssoModify)
  }

  updateSso = async (id:any,email:any,sso:any) => {
    const formBody = new FormData()
    formBody.append('account[sso]', sso)
    this.updateAdminSsoId = await this.apiCall({
      method: "PUT",
      endPoint: `${configJSON.updateUserSsoApiEndPoint}/${id}/${configJSON.updateUserSsoApiEndPointMiddle}?email=${email}`,
      formBody,
    });
  };


  // Customizable Area End
}