import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  getStorageData,
  removeStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  username: string;
  password: string;
  isChecked: boolean;
  showPassword: boolean;
  errors: any;
  textColor: string;
  textColor1: string;
  isForgot: boolean;
  showMessage: boolean;
  inActiveMessage: string;
  isRemember: boolean;
  Sso: boolean;
  checkbox: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLoginCallId: string = "";
  getSsoCallId: string = "";
  getCheckboxId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      username: "",
      password: "",
      isChecked: false,
      showPassword: false,
      errors: {},
      textColor: "",
      textColor1: "",
      isForgot: false,
      showMessage: false,
      inActiveMessage: "",
      isRemember: false,
      Sso: false,
      checkbox: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const name = await getStorageData("username");
    this.getCheckBox(name);
    this.getSso(name);
  }

  getNavigationParams = async (message: any) => {
    const namePromise = getStorageData("username");
    const passwordPromise = getStorageData("password");
    const [name, password] = await Promise.all([namePromise, passwordPromise]);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const naviParams = message.getData(
        getName(MessageEnum.EmailAccountLoginPayload)
      );
      if (naviParams.isAgree) {
        this.setState({ isChecked: true });
        this.setState({ textColor: "green" });
      }

      if (name.length > 1) {
        this.setState({ username: name });
      }

      if (password.length > 1) {
        this.setState({ password: password });
      }
    }
  };
  handleCheckboxChange = (value: any) => {
    const color = value.target.checked ? "green" : "";
    this.setState(prevState => ({
      isChecked: !prevState.isChecked,
      textColor: color
    }));
  };

  roleMapping: { [key: string]: string } = {
    Admin: "Adminconsole",
    Employee: "VisualAnalytics",
    Manager: "VisualAnalytics"
  };

  errorMapping: { [key: string]: string } = {
    "Invalid Email": "username",
    "Invalid Password!": "password"
  };

  getLogin = async () => {
    const body = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.username.replace(/\s/g, ""),
          password: this.state.password,
          is_remember: this.state.isRemember,
          t_and_c: true
        }
      }
    };

    this.getLoginCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "bx_block_login/login",
      body
    });
  };
  handleSubmit = () => {
    const validationErrors: { [key: string]: string } = {};
    if (!this.state.username) {
      validationErrors.username = configJSON.userNameErrorText;
    }
    if (!this.state.password) {
      validationErrors.password = configJSON.passWordErrorText;
    }
    if (!this.state.checkbox && !this.state.isChecked) {
      validationErrors.isChecked = configJSON.checkErrorText;
      this.setState({ textColor: "red" });
    }
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors, showMessage: false });
    } else {
      this.getLogin();
    }
  };
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleForgot = () => {
    this.setState(prev => ({ isForgot: !prev.isForgot }));
  };
  handleCheckRemember = (value: any) => {
    const color = value.target.checked ? "green" : "";
    this.setState(prev => ({
      isRemember: !prev.isRemember,
      textColor1: color
    }));
  };

  debounce(func: any, delay: number) {
    let timeout: any;
    return function(...args: any) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleOnChange = async (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case "username":
        this.setState({ username: value });
        setStorageData("username", value);
        this.debouncedSearch(value);
        this.debouncedCheckbox(value);
        break;
      case "password":
        this.setState({ password: value });
        setStorageData("password", value);
        break;
    }
  };

  getSso = async (email: any) => {
    this.getSsoCallId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "GET",
      endPoint: `${configJSON.adminSsoApiEndPoint}?email=${email}`
    });
  };

  debouncedSearch = this.debounce(this.getSso, 300);

  getCheckBox = async (email: any) => {
    this.getCheckboxId = await this.apiCall({
      contentType: configJSON.exampleApiContentType,
      method: "GET",
      endPoint: `${configJSON.checkboxApiEndPoint}?email=${email}`
    });
  };

  debouncedCheckbox = this.debounce(this.getCheckBox, 300);

  getSsoApi = (responseJson: any) => {
    if (!responseJson.error) {
      this.setState({ Sso: responseJson.sso });
    }
  };

  getCheckboxApi = (responseJson: any) => {
    if (!responseJson.error) {
      this.setState({
        checkbox: responseJson.t_and_c
      });
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.getNavigationParams(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getLoginCallId) {
        if (responseJson.errors) {
          const errorMessage = responseJson.errors[0].failed_login;
          const accountError = responseJson.errors[0].account;
          const errorType = this.errorMapping[errorMessage];
          this.setState({
            errors: { [errorType]: errorMessage },
            showMessage: true,
            inActiveMessage: accountError
          });
        }
        removeStorageData("username");
        removeStorageData("password");
        setStorageData("role", responseJson.meta.role);
        setStorageData("token", responseJson.meta.token);
        const url = this.roleMapping[responseJson.meta.role];
        this.props.navigation.navigate(url);
      } else {
        console.log("login testing", errorReponse);
      }

      switch (apiRequestCallId) {
        case this.getSsoCallId:
          this.getSsoApi(responseJson);
          break;

        case this.getCheckboxId:
          this.getCheckboxApi(responseJson);
          break;
      }
    }
    // Customizable Area End
  }
}
