Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.logInTitleText = "Log In";
exports.logInBodyText = "The reason why we require login.";
exports.loginFacebookButtonText = "Log in With Facebook";
exports.loginGoogleButtonText = "Log in With Google";
exports.loginButtonText = "Log in with email and password";
exports.orText = "or";
exports.userNameErrorText = "Username is required";
exports.checkErrorText = "Check is required";
exports.passWordErrorText = "Password is required";
exports.emailText="Email ID";
exports.emailPlaceholderText="Enter your Email";
exports.passWordPlaceholderText="Enter your Password";
exports.forgotNoteText="Please call or contact your Admin to reset the password";
exports.forgotText="Forgot Password";
exports.backButtonText="Back to Log in";
exports.remindTest="Remember me";
exports.adminSsoApiEndPoint="bx_block_user/users/get_sso";
exports.checkboxApiEndPoint="account_block/account_sessions/t_and_c_get";

// Customizable Area End