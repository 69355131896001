Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "adminconsole2";
exports.labelBodyText = "adminconsole2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.headingText = "Admin Console";
exports.userTest = "User";
exports.employeeTest = "Employee Name";
exports.departmentText  = "Department";
exports.roleText  = "Role";
exports.emailText  = "E-Mail ID";
exports.totalEmployeeText  = "Total Employees";
exports.accessText  = "Access";
exports.actionText  = "Action";
exports.viewText = "View Only";
exports.canEditTeXt = "Can Edit";
exports.searchPlaceholder = "Search Employee";
exports.assignText = "Assign Relationship";
exports.createAccountText = "Create New Account";
exports.editReportText = "Edit Profile";
exports.deleteReportText = "Delete Profile";
exports.currentUserApiEndPoint = "bx_block_user/users/current_user_info";
exports.deleteMessageText = "You have successfully deleted the profile of ";
exports.okayText = "Okay";
exports.alertText = "Are you sure you want to delete profile of ";
exports.yesText = "Yes";
exports.noText = "No";
exports.usersListApiEndPoint = "/bx_block_user/users/users_list";
exports.userChangeRoleApiEndPoint = "/bx_block_user/users/grant_permissions";
exports.headingEditText = "Edit Profile";
exports.nameText = "Name";
exports.emailText = "Email ID";
exports.passWordPlaceholderText = "Current Password";
exports.transportText="Transport";
exports.managementText="Management";
exports.employeeText='Employee';
exports.managerText='Manager';
exports.saveUpdateButtonText = "Save & Update";
exports.profilePictureText = 'Profile Picture';
exports.uploadPhoto = "Upload Photo";
exports.changePasswordButtonText = "Change Password";
exports.oldPasswordText = "Old Password";
exports.createNewPasswordText = "Create New Password";
exports.confirmPasswordText = "Confirm Password";
exports.deleteUserAccountApiEndPoint = "/bx_block_user/users";
exports.getAccountApiEndPoint = "/bx_block_user/users";
exports.updateUserAccountApiEndPoint = '/bx_block_user/users';
exports.updateUserSsoApiEndPoint = 'bx_block_user/users';
exports.updateUserSsoApiEndPointMiddle = 'change_sso';
exports.successfullyUpdateText = "You’ve successfully updated the profile of ";
exports.changePasswordApiEndPoint = "/bx_block_admin/admins/change_password";
exports.changePasswordMessageTest1 = "Password of profile ";
exports.changePasswordMessageTest2 = " has been changed successfully!";
exports.adminButtonText = "Go to Admin Console";
exports.passWordErrorText = "The entered password doesn't match the confirm password. Check again";
exports.departmentOptionText1='Sales';
exports.departmentOptionText2='Customer Service';
exports.departmentOptionText3='Operations';
exports.departmentOptionText4='Linehaul';
exports.departmentOptionText5='Finance';
exports.departmentOptionText6='Billing';
exports.departmentOptionText7='Pricing';
exports.departmentOptionText8='Human Resources';
exports.departmentOptionText9='Information Technology';
exports.departmentOptionText10='Executive';
exports.departmentPlaceholderText = "Select Department";
exports.rolePlaceholderText = "Select Role";
exports.jobErrorText="Job is required";
exports.administrationAdmin="VP, Administration";
exports.linehaulAdmin="VP, Linehaul";
exports.operationsAdmin="VP, Operations";
exports.salesClientAdmin="VP, Sales and Client Services";
exports.sfoAdmin="SCM, SFO";
exports.ontAdmin="SCM, ONT";
exports.ewrAdmin="SCM, EWR";
exports.dalAdmin="SCM, DAL";
exports.htxAdmin="SCM, HTX";
exports.atmAdmin="SCM, ATM";
exports.laxAdmin="SCM, LAX";
exports.lrdAdmin="SCM, LRD";
exports.pricingAdmin="Director, Pricing";
exports.agentAdmin="Director, Agent Operations";
exports.directorOperationsAdmin="Director, Operations";
exports.directorSalesAdmin="Director, Sales";
exports.crossBorderAdmin="Director, Cross Border Business";
exports.applicationDevelopmentAdmin="Director, Application Development";
exports.infrastructureAdmin="Director, Infrastructure";
exports.humanResourcesAdmin="Director, Human Resources";
exports.servicesManagerAdmin="Client Services Manager";
exports.safetyManagerAdmin="Safety Manager";
exports.chiefTechnologyAdmin="Chief Technology Officer";
exports.chiefInformationAdmin="Chief Information Officer";
exports.chiefFinancialAdmin="Chief Financial Officer";
exports.executiveOfficerAdmin="Executive Officer";
exports.administrationValueAdmin="VP_Administration";
exports.linehaulValueAdmin="VP_Linehaul";
exports.operationsValueAdmin="VP_Operations";
exports.salesClientValueAdmin="VP_Sales_and_Client_Services";
exports.sfoValueAdmin="SCM_SFO";
exports.ontValueAdmin="SCM_ONT";
exports.ewrValueAdmin="SCM_EWR";
exports.dalValueAdmin="SCM_DAL";
exports.htxValueAdmin="SCM_HTX";
exports.atmValueAdmin="SCM_ATM";
exports.laxValueAdmin="SCM_LAX";
exports.lrdValueAdmin="SCM_LRD";
exports.pricingValueAdmin="Director_Pricing";
exports.agentValueAdmin="Director_Agent_Operations";
exports.directorOperationsValueAdmin="Director_Operations";
exports.directorSalesValueAdmin="Director_Sales";
exports.crossBorderValueAdmin="Director_Cross_Border_Business";
exports.applicationDevelopmentValueAdmin="Director_Application_Development";
exports.infrastructureValueAdmin="Director_Infrastructure";
exports.humanResourcesValueAdmin="Director_Human_Resources";
exports.servicesManagerValueAdmin="Client_Services_Manager";
exports.safetyManagerValueAdmin="Safety_Manager";
exports.chiefTechnologyValueAdmin="Chief_Technology_Officer";
exports.chiefInformationValueAdmin="Chief_Information_Officer";
exports.chiefFinancialValueAdmin="Chief_Financial_Officer";
exports.executiveOfficerValueAdmin="Executive_Officer";
exports.jobPlaceholder="Select Job";
exports.jobTitleText="Job Title";
exports.adminListTest="Admin List";
exports.adminListApiEndPoint="bx_block_user/users/admin_list";
exports.ssoTest='SSO';
exports.businessAnalystText='Business_Analyst_Controller';
exports.businessAnalystKeyText='Business Analyst Controller';
exports.srNetworkText='Sr_Network_Engineer';
exports.srNetworkKeyText='Sr. Network Engineer';
exports.srSystemsText='Sr_Systems_Engineer';
exports.srSystemsKeyText='Sr. Systems Engineer';
exports.projectManagerText='Project_Manager';
exports.projectManagerKeyText='Project Manager';
exports.applicationDeveloperText='Application_Developer';
exports.applicationDeveloperKeyText='Application Developer';
exports.srApplicationsText='Sr_Applications_Developer';
exports.srApplicationsKeyText='Sr. Applications Developer';
exports.srSystemsAdministratorText='Sr_Systems_Administrator';
exports.srSystemsAdministratorKeyText='Sr. Systems Administrator';
exports.systemsAdministratorText='Systems_Administrator';
exports.systemsAdministratorKeyText='Systems Administrator';
exports.technicalArchitectText='Technical_Architect';
exports.technicalArchitectKeyText='Technical Architect';
exports.employeeOppText='Employee';
exports.supervisorText='Supervisor';
exports.managerOppText='Manager';
exports.vicePresidentText='Vice_President';
exports.vicePresidentKeyText='Vice President';
// Customizable Area End